import React, {
  createElement,
  useRef,
  useEffect,
  useState,
  useContext,
} from "react";
import classnames from "classnames";
import { bpInRange, BreakpointContext } from "@/context/BreakpointContext";
import SbEditable from "storyblok-react";
import {
  MARK_ITALIC,
  NODE_PARAGRAPH,
  MARK_BOLD,
  render,
} from "storyblok-rich-text-react-renderer";
import styles from "./custom-heading.module.scss";
import gsap from "gsap";
import { randomInteger } from "../../helpers/utils";
import { useInView } from "../../helpers/hooks";
import { CustomHeadingStoryblok } from "@/types/component-types-sb";

interface GradientTextProps {
  children: React.ReactNode;
}

const GradientText = ({ children }: GradientTextProps) => {
  const [tl] = useState(gsap.timeline({ paused: true, repeat: -1 }));
  const ref = useRef(null);
  const inView = useInView(ref);
  const bgX = `${randomInteger(0, 80)}%`;
  const bgY = `${randomInteger(0, 80)}%`;

  useEffect(() => {
    tl.fromTo(
      ref.current,
      { "--bgX": bgX, "--bgY": bgY },
      {
        "--bgX": "200%",
        "--bgY": "50%",
        duration: 45,
        ease: "linear",
        repeat: -1,
      }
    );
    inView && ref.current ? tl.play() : tl.clear();
  }, [ref, inView]);
  return (
    <span
      suppressHydrationWarning
      ref={ref}
      className={styles.gradient}
      style={{ ["--bgX" as string]: bgX, ["--bgY" as string]: bgY }}
    >
      {children}
    </span>
  );
};

interface CustomHeadingProps {
  blok: CustomHeadingStoryblok;
  className?: string;
  styleVars?: React.CSSProperties;
  renderDefault?: string;
  children?: React.ReactNode;
}
const CustomHeading = (props: CustomHeadingProps) => {
  const { currentBp } = useContext(BreakpointContext);
  const smallViewport = bpInRange({ currentBp, bp: "m", range: "down" });
  const {
    blok: { content, alignment = "", alignment_large_screens, highlight_color },
    className,
    styleVars,
    renderDefault = "h3",
    children,
  } = props;

  const currAlignment = smallViewport
    ? styles[alignment]
    : alignment_large_screens
    ? styles[alignment_large_screens] || styles[alignment]
    : styles[alignment];

  const classes = classnames(styles.customHeading, currAlignment, className);

  const customHighlight = highlight_color?.color
    ? { ["--color-highlight" as string]: highlight_color.color }
    : {};

  const emRenderer = (children: React.ReactNode) =>
    highlight_color?.color ? (
      <em className={styles.highlight} style={customHighlight}>
        {children}
      </em>
    ) : (
      <GradientText>{children}</GradientText>
    );
  const paraRenderer = (children: React.ReactNode) =>
    createElement(renderDefault, {}, children);

  const textChildren = render(content, {
    markResolvers: {
      [MARK_ITALIC]: emRenderer,
      [MARK_BOLD]: (children) => {
        return <div style={{ fontSize: 32, marginTop: 16 }}>{children}</div>;
      },
    },
    nodeResolvers: {
      [NODE_PARAGRAPH]: paraRenderer,
    },
  });

  return (
    // @ts-expect-error
    <SbEditable content={props.blok}>
      <div className={classes} style={styleVars}>
        {children}
        {content && textChildren}
      </div>
    </SbEditable>
  );
};

export default CustomHeading;
