import { useEffect, useState, RefObject } from "react";

export function useIsInEditorHook() {
  let isIn = false;

  if (typeof window !== `undefined`) {
    const fullUrl = window.location.href;
    isIn = fullUrl.search("_storyblok") > -1;
  }

  return isIn;
}

export function useInView(ref: RefObject<Element>) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );

    if (!ref.current) return;

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}
